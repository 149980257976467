import { isEmpty } from 'ramda'
import { getPodTemplate } from '../config-maps/helpers'
import { memoize } from 'utils/misc'
import { StatusCellModel } from 'core/elements/grid/cells/GridStatusCell'

export const getServicesForResource = memoize((resource = {}, services = []) => {
  const podTemplate = getPodTemplate(resource)
  return services.filter(
    (service) =>
      service?.spec?.selector &&
      labelsMatchSelector(podTemplate?.metadata?.labels, service?.spec?.selector),
  )
})

// Commenting this for optimization
// export const labelsMatchSelector = (labels = {}, selector = {}) => {
//   if (isEmpty(labels) || isEmpty(selector)) {
//     return false
//   }
//   for (const [selectorKey, selectorValue] of Object.entries(selector)) {
//     if (!labels.hasOwnProperty(selectorKey) || labels[selectorKey] !== selectorValue) {
//       return false
//     }
//   }
//   return true
// }

export const labelsMatchSelector = (labels = {}, selector = {}) =>
  Object.keys(selector).every((key) => labels[key] === selector[key])

export const getServiceStatus = (status): StatusCellModel => ({
  variant: status === 'OK' ? 'success' : 'warning',
  label: status === 'OK' ? 'Connected' : 'Connecting',
})
