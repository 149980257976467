// Constants
import { allKey } from 'app/constants'

// Utils
import { routes } from 'core/utils/routes'
import {
  isHealthyStatus,
  isTransientStatus,
  isUnhealthyStatus,
} from '../../../infrastructure/components/clusters/ClusterStatusUtils'

// Data
import { listClusters } from '../../../infrastructure/components/clusters/newActions'
import { clustersSelector } from '../../../infrastructure/components/clusters/selectors'

import { listImportedClusters } from 'app/plugins/infrastructure/components/importedClusters/new-actions'
import { importedClustersSelector } from 'app/plugins/infrastructure/components/importedClusters/selectors'
import { ImportedClusterSelector } from 'app/plugins/infrastructure/components/importedClusters/model'

import { listNodes } from 'app/plugins/infrastructure/components/nodes/new-actions'
import { combinedNodesSelector } from 'app/plugins/infrastructure/components/nodes/selectors'

import { listCloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/new-actions'
import { cloudProvidersSelector } from 'app/plugins/infrastructure/components/cloudProviders/selectors'
import { CloudProviders } from 'app/plugins/infrastructure/components/cloudProviders/model'

import { listUsers } from 'app/plugins/account/components/userManagement/users/new-actions'
import { usersSelector } from 'app/plugins/account/components/userManagement/users/selectors'

import { listTenants } from 'app/plugins/account/components/userManagement/tenants/new-actions'
import { tenantsSelector } from 'app/plugins/account/components/userManagement/tenants/selectors'

import { listDeployments } from '../deployments/new-actions'
import { rawDeploymentsSelector } from '../deployments/selectors'

import { listServices } from '../services/new-actions'
import { rawServicesSelector } from '../services/selectors'

import { listPods } from '../pods/new-actions'
import { rawPodsSelector } from '../pods/selectors'

import { IStatusCardWithFilterProps } from './card-templates/model'

const nodeHealthStatus = ({ status }) => {
  if (status === 'converging') {
    return status
  }
  return status === 'disconnected'
    ? 'unknown'
    : status?.toLowerCase() === 'ok'
    ? 'healthy'
    : 'unhealthy'
}

export enum DashBoardStatusCardTypes {
  Cluster = 'cluster',
  ImportedCluster = 'importedCluster',
  Node = 'node',
  Cloud = 'cloud',
  User = 'user',
  Tenant = 'tenant',
  Deployment = 'deployment',
  Service = 'service',
  Pod = 'pod',
}

export const clusterStatusCardProps: IStatusCardWithFilterProps = {
  entity: 'cluster',
  permissions: ['admin'], // Technically non-admins have read-only access
  route: routes.cluster.managed.list.path(),
  addRoute: routes.cluster.add.root.path(),
  title: 'Clusters Total',
  icon: 'project-diagram',
  data: {
    loader: listClusters,
    selector: clustersSelector,
    params: {},
  },
  quantityFn: (clusters) => ({
    quantity: clusters.length,
    pieData: [
      {
        value: clusters.filter((cluster) => isHealthyStatus(cluster.healthStatus)).length,
        ...baseClusterPieData.healthy,
      },
      {
        value: clusters.filter((cluster) => cluster.healthStatus === 'partially_healthy').length,
        ...baseClusterPieData.partially_healthy,
      },
      {
        value: clusters.filter((cluster) => isTransientStatus(cluster.healthStatus)).length,
        ...baseClusterPieData.converging,
      },
      {
        value: clusters.filter((cluster) => isUnhealthyStatus(cluster.healthStatus)).length,
        ...baseClusterPieData.unhealthy,
      },
    ],
    piePrimary: 'healthy',
  }),
  sampleDataFn: () => ({
    quantity: 8,
    pieData: [
      {
        value: 6,
        ...baseClusterPieData.healthy,
      },
      {
        value: 1,
        ...baseClusterPieData.partially_healthy,
      },
      {
        value: 0,
        ...baseClusterPieData.converging,
      },
      {
        value: 1,
        ...baseClusterPieData.unhealthy,
      },
    ],
    piePrimary: 'healthy',
  }),
}

const baseClusterPieData = {
  healthy: {
    name: 'healthy',
    color: 'fadedSuccess',
    info: 'Platform9 components & K8s API are responding',
  },
  partially_healthy: {
    name: 'partially_healthy',
    color: 'fadedWarning',
    info: 'Platform9 components or K8s API are not responding',
  },
  converging: {
    name: 'converging',
    color: 'fadedDanger',
    info: 'Platform9 components are upgrading or installing',
  },
  unhealthy: {
    name: 'unhealthy',
    color: 'fadedError',
    info: 'Platform9 components are offline and not responding',
  },
} as const

export const importedClusterStatusCardProps: IStatusCardWithFilterProps = {
  entity: 'importedCluster',
  permissions: ['admin'],
  route: 'n/a',
  addRoute: 'n/a',
  title: 'Clusters Total',
  icon: 'n/a',
  data: {
    loader: listImportedClusters,
    selector: importedClustersSelector,
    params: {},
  },
  quantityFn: (clusters: ImportedClusterSelector[]) => ({
    quantity: clusters.length,
    // Possible "" value, not sure what to do with that
    pieData: [
      {
        value: clusters.filter((cluster) => cluster.status.phase === 'Running').length,
        ...baseImportedClusterPieData.running,
      },
      {
        value: clusters.filter((cluster) => cluster.status.phase === 'Pending').length,
        ...baseImportedClusterPieData.pending,
      },
      {
        value: clusters.filter((cluster) => cluster.status.phase === 'Terminating').length,
        ...baseImportedClusterPieData.terminating,
      },
      {
        value: clusters.filter((cluster) => cluster.status.phase === 'Failing').length,
        ...baseImportedClusterPieData.failing,
      },
    ],
    piePrimary: 'running',
  }),
  sampleDataFn: () => ({
    quantity: 12,
    pieData: [
      {
        value: 8,
        ...baseImportedClusterPieData.running,
      },
      {
        value: 2,
        ...baseImportedClusterPieData.pending,
      },
      {
        value: 1,
        ...baseImportedClusterPieData.terminating,
      },
      {
        value: 1,
        ...baseImportedClusterPieData.failing,
      },
    ],
    piePrimary: 'running',
  }),
}
const baseImportedClusterPieData = {
  running: {
    name: 'running',
    color: 'fadedSuccess',
  },
  pending: {
    name: 'pending',
    color: 'fadedWarning',
  },
  terminating: {
    name: 'terminating',
    color: 'fadedDanger',
  },
  failing: {
    name: 'failing',
    color: 'fadedError',
  },
} as const

export const nodeStatusCardProps: IStatusCardWithFilterProps = {
  entity: 'node',
  permissions: ['admin'],
  route: routes.nodes.list.path(),
  addRoute: routes.nodes.add.path(),
  title: 'Nodes',
  icon: 'ball-pile',
  data: {
    loader: listNodes,
    selector: combinedNodesSelector,
    params: {},
  },
  quantityFn: (nodes) => ({
    quantity: nodes.length,
    pieData: [
      {
        value: nodes.filter((node) => nodeHealthStatus(node) === 'healthy').length,
        ...baseNodePieData.healthy,
      },
      {
        value: nodes.filter((node) => nodeHealthStatus(node) === 'unknown').length,
        ...baseNodePieData.unknown,
      },
      {
        value: nodes.filter((node) => nodeHealthStatus(node) === 'converging').length,
        ...baseNodePieData.converging,
      },
      {
        value: nodes.filter((node) => nodeHealthStatus(node) === 'unhealthy').length,
        ...baseNodePieData.unhealthy,
      },
    ],
    piePrimary: 'healthy',
  }),
  sampleDataFn: () => ({
    quantity: 36,
    pieData: [
      {
        value: 29,
        ...baseNodePieData.healthy,
      },
      {
        value: 1,
        ...baseNodePieData.unknown,
      },
      {
        value: 4,
        ...baseNodePieData.converging,
      },
      {
        value: 2,
        ...baseNodePieData.unhealthy,
      },
    ],
    piePrimary: 'healthy',
  }),
}

const baseNodePieData = {
  healthy: {
    name: 'healthy',
    color: 'fadedSuccess',
  },
  unknown: {
    name: 'unknown',
    color: 'fadedWarning',
  },
  converging: {
    name: 'converging',
    color: 'fadedDanger',
  },
  unhealthy: {
    name: 'unhealthy',
    color: 'fadedError',
  },
} as const

export const cloudStatusCardProps: IStatusCardWithFilterProps = {
  entity: 'cloud',
  permissions: ['admin'],
  route: routes.cloudProviders.aws.list.path(),
  addRoute: routes.cloudProviders.add.path(),
  title: 'Cloud Accounts',
  icon: 'cloud',
  data: {
    loader: listCloudProviders,
    selector: cloudProvidersSelector,
    params: {},
  },
  quantityFn: (clouds) => ({
    quantity: clouds.length,
  }),
  sampleDataFn: () => ({
    quantity: 4,
  }),
}

const baseCloudPieData = {
  AWS: {
    name: 'AWS',
    color: 'aws',
  },
  Azure: {
    name: 'Azure',
    color: 'azure',
  },
  Google: {
    name: 'Google',
    color: 'google',
  },
} as const

export const userStatusCardProps = {
  entity: 'user',
  permissions: ['admin'],
  overallPermissions: ['admin'],
  route: routes.userManagement.root.path(),
  addRoute: routes.userManagement.addUser.path(),
  title: 'Users',
  icon: 'user',
  data: {
    loader: listUsers,
    selector: usersSelector,
    params: {},
  },
  quantityFn: (users) => ({
    quantity: users.length,
  }),
  sampleDataFn: () => ({
    quantity: 18,
  }),
}
export const tenantStatusCardProps = {
  entity: 'tenant',
  permissions: ['admin'],
  overallPermissions: ['admin'],
  route: routes.userManagement.root.path({ tab: 'tenants' }),
  addRoute: routes.userManagement.addTenant.path(),
  title: 'Tenants',
  icon: 'users-class',
  data: {
    loader: listTenants,
    selector: tenantsSelector,
    params: {},
  },
  quantityFn: (tenants) => ({
    quantity: tenants.length,
  }),
  sampleDataFn: () => ({
    quantity: 6,
  }),
}
export const deploymentStatusCardProps = {
  entity: 'deployment',
  route: routes.deployments.list.path(),
  addRoute: routes.deployments.add.path(),
  title: 'Deployments',
  icon: 'window',
  data: {
    loader: listDeployments,
    selector: rawDeploymentsSelector,
    params: { clusterId: allKey, useGlobalParams: true },
  },
  quantityFn: (deployments) => ({
    quantity: deployments.length,
  }),
  sampleDataFn: () => ({
    quantity: 12341,
  }),
}
export const serviceStatusCardProps = {
  entity: 'service',
  route: routes.services.list.path(),
  addRoute: routes.services.add.path(),
  title: 'Services',
  icon: 'tasks-alt',
  data: {
    loader: listServices,
    selector: rawServicesSelector,
    params: { clusterId: allKey, useGlobalParams: true },
  },
  quantityFn: (services) => ({
    quantity: services.length,
    pieData: [
      {
        value: services.filter((service) => service?.status === 'OK').length,
        ...baseServicePieData.ok,
      },
      {
        value: services.filter((service) => service?.status === 'Pending').length,
        ...baseServicePieData.pending,
      },
    ],
    piePrimary: 'Connected',
  }),
  sampleDataFn: () => ({
    quantity: 32873,
    pieData: [
      {
        value: 28441,
        ...baseServicePieData.ok,
      },
      {
        value: 3164,
        ...baseServicePieData.pending,
      },
    ],
    piePrimary: 'Connected',
  }),
}

const baseServicePieData = {
  ok: {
    name: 'Connected',
    color: 'fadedSuccess',
  },
  pending: {
    name: 'Connecting',
    color: 'fadedDanger',
  },
} as const

export const podStatusCardProps = {
  entity: 'pod',
  route: routes.pods.list.path(),
  addRoute: routes.pods.add.path(),
  title: 'Pods',
  icon: 'cubes',
  data: {
    loader: listPods,
    selector: rawPodsSelector,
    params: { clusterId: allKey, useGlobalParams: true },
  },
  quantityFn: (pods) => ({
    quantity: pods.length,
    pieData: [
      {
        value: pods.filter((pod) => pod?.status?.phase === 'Running').length,
        ...basePodPieData.running,
      },
      {
        value: pods.filter((pod) => pod?.status?.phase === 'Pending').length,
        ...basePodPieData.pending,
      },
      {
        value: pods.filter((pod) => pod?.status?.phase === 'Unknown').length,
        ...basePodPieData.unknown,
      },
      {
        value: pods.filter((pod) => pod?.status?.phase === 'Succeeded').length,
        ...basePodPieData.succeeded,
      },
      {
        value: pods.filter((pod) => pod?.status?.phase === 'Failed').length,
        ...basePodPieData.failed,
      },
    ],
    piePrimary: 'running',
  }),
  sampleDataFn: () => ({
    quantity: 32873,
    pieData: [
      {
        value: 28441,
        ...basePodPieData.running,
      },
      {
        value: 3164,
        ...basePodPieData.pending,
      },
      {
        value: 1072,
        ...basePodPieData.unknown,
      },
      {
        value: 196,
        ...basePodPieData.failed,
      },
    ],
    piePrimary: 'running',
  }),
}

const basePodPieData = {
  running: {
    name: 'running',
    color: 'fadedSuccess',
  },
  pending: {
    name: 'pending',
    color: 'fadedDanger',
  },
  unknown: {
    name: 'unknown',
    color: 'unknown',
  },
  failed: {
    name: 'failed',
    color: 'fadedError',
  },
  succeeded: {
    name: 'succeeded',
    color: 'fadedPrimary',
  },
} as const

export const dashboardCardsByType = {
  [DashBoardStatusCardTypes.Cluster]: clusterStatusCardProps,
  [DashBoardStatusCardTypes.ImportedCluster]: importedClusterStatusCardProps,
  [DashBoardStatusCardTypes.Node]: nodeStatusCardProps,
  [DashBoardStatusCardTypes.Cloud]: cloudStatusCardProps,
  [DashBoardStatusCardTypes.User]: userStatusCardProps,
  [DashBoardStatusCardTypes.Tenant]: tenantStatusCardProps,
  [DashBoardStatusCardTypes.Deployment]: deploymentStatusCardProps,
  [DashBoardStatusCardTypes.Service]: serviceStatusCardProps,
  [DashBoardStatusCardTypes.Pod]: podStatusCardProps,
}
