import ActionsSet from 'core/actions/ActionsSet'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import { ensureArray, pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import jsYaml from 'js-yaml'
import ListAction from 'core/actions/ListAction'
import CreateAction from 'core/actions/CreateAction'
import UpdateAction from 'core/actions/UpdateAction'
import DeleteAction from 'core/actions/DeleteAction'
import { someAsync } from 'utils/async'
import { flatten } from 'ramda'

const { qbert } = ApiClient.getInstance()

const serviceActions = ActionsSet.make<DataKeys.KubeServices>({
  uniqueIdentifier: 'id',
  // indexBy: ['clusterId', 'namespace'],
  indexBy: ['clusterId'],
  entityName: entityNamesByKey[DataKeys.KubeServices],
  cacheKey: DataKeys.KubeServices,
})

export default serviceActions

export const listServices = serviceActions.add(
  new ListAction<DataKeys.KubeServices, { clusterId: string; namespace?: string }>(
    async (params) => {
      const { clusterId } = params
      Bugsnag.leaveBreadcrumb('Attempting to get cluster services', params)
      // return qbert.getClusterKubeServices(clusterId, namespace)
      return qbert.getAllClusterKubeServices(clusterId)
    },
  ),
)

export const createService = serviceActions.add(
  new CreateAction<DataKeys.KubeServices, { clusterId: string; namespace: string; yaml: string }>(
    async ({ clusterId, namespace, yaml }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create new cluster service', {
        clusterId,
        namespace,
        yaml,
      })
      const body = jsYaml.load(yaml)
      const created = await qbert.createService(clusterId, namespace, body)
      trackEvent('Create New Service', {
        clusterId,
        namespace,
        name: pathStr('metadata.name', created),
        id: pathStr('metadata.uid', created),
      })
      return created
    },
  ),
)

export const updateService = serviceActions.add(
  new UpdateAction<
    DataKeys.KubeServices,
    {
      clusterId: string
      namespace: string
      name: string
      body: unknown
      requestType: string
      contentType?: string
    }
  >(async ({ clusterId, namespace, name, body, requestType = 'put', contentType }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update service', {
      clusterId,
      namespace,
      name,
      body,
    })
    const updateFn =
      requestType === 'patch' ? qbert.patchClusterKubeService : qbert.updateClusterKubeService
    const updatedService = await updateFn(clusterId, namespace, name, body, contentType)
    trackEvent('Update Service', { clusterId, namespace, name })
    return updatedService
  }),
)

export const deleteService = serviceActions.add(
  new DeleteAction<
    DataKeys.KubeServices,
    { clusterId: string; namespace: string; name: string; id: string }
  >(async ({ clusterId, namespace, name, id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete cluster service', {
      clusterId,
      namespace,
      name,
      id,
    })
    await qbert.deleteService(clusterId, namespace, name)
    trackEvent('Delete Service', { clusterId, namespace, name, id })
  }),
)
