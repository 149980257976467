import React, { useCallback, useMemo, useRef } from 'react'
import { projectAs, isNilOrEmpty, emptyArr, ensureArray } from 'utils/fp'
import { uniqBy, prop } from 'ramda'
import useListAction from 'core/hooks/useListAction'
import { listNamespaces } from 'k8s/components/namespaces/new-actions'
import { namespacesSelector } from 'k8s/components/namespaces/selectors'
import MultiDropdown, { MultiDropdownProps } from 'core/elements/dropdown/MultiDropdown'
import { useAppSelector } from 'app/store'
import { CancellableDebounceFn, debounce } from 'utils/async'

interface NamespacePicklistProps extends Omit<MultiDropdownProps<string>, 'items'> {
  clusters: string[]
}

export default function GlobalMultiNamespaceDropdown({
  label = 'Namespace',
  compact = true,
  clusters,
  loading,
  onChange,
  value,
  ...rest
}: NamespacePicklistProps) {
  const { loading: isLoading } = useListAction(listNamespaces, {
    params: { clusterId: clusters },
    requiredParams: ['clusterId'],
  })
  const debounceRef = useRef<CancellableDebounceFn>()
  const namespaces = useAppSelector(namespacesSelector)
  const namespacesNames = useMemo(() => namespaces.map((ns) => ns.name), [namespaces])
  const options = useMemo(() => {
    return projectAs({ label: 'name', value: 'name' }, uniqBy(prop('name'), namespaces))
  }, [namespaces])
  const selectedNamespaces = useMemo(() => {
    if (isNilOrEmpty(value) || isNilOrEmpty(namespacesNames)) {
      return emptyArr as string[]
    }
    return ensureArray(value).filter((namespaceName) => namespacesNames.includes(namespaceName))
  }, [namespacesNames, value])

  const debouncedOnChange = useCallback(
    (selectedValues) => {
      debounceRef.current?.cancel()
      debounceRef.current = debounce(() => {
        onChange(selectedValues)
      }, 1000)
      debounceRef.current()
    },
    [onChange],
  )

  return (
    <MultiDropdown
      compact={compact}
      onChange={debouncedOnChange}
      label={label}
      showAll
      loading={loading || isLoading}
      items={options}
      value={selectedNamespaces}
      condensed={true}
      {...rest}
    />
  )
}
