import { createSelector } from '@reduxjs/toolkit'
import store, { AppSelector } from 'app/store'
import { assocPath, complement, filter, isNil, map, pipe, propEq } from 'ramda'
import { arrayIfEmpty, emptyArr, emptyObj, filterIf } from 'utils/fp'
import { allKey } from 'app/constants'
import { memoize, pathJoin, durationBetweenDates } from 'utils/misc'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import { IPodSelector, PodStatus } from './model'
import { IDataKeys } from 'k8s/datakeys.model'
import {
  getK8sDashboardLinkFromVersion,
  getScopedClusterProxyEndpoint,
} from '../../../infrastructure/components/clusters/action-helpers'
import { getPodResourceStats } from './helpers'
import { podMetricsByClusterIdAndNamespaceSelector } from './metrics/selectors'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'
import { allClustersSelector } from 'app/plugins/infrastructure/components/combinedClusters/selectors'
import { qbertEndpointSelector } from 'app/plugins/infrastructure/components/common/selectors'

// const getContainerInfo = (
//   clusterId,
//   id,
//   containers = emptyArr,
//   containerStatuses = emptyArr,
//   containerMetrics = emptyArr,
// ) => {
//   const containerStatusesMap = new Map()
//   for (const s of containerStatuses) {
//     containerStatusesMap.set(s.name, s)
//   }
//   const containerMetricsMap = new Map()
//   for (const m of containerMetrics) {
//     containerMetricsMap.set(m.name, m)
//   }

//   const containersArray = []
//   for (const c of containers) {
//     const status = containerStatusesMap.get(c.name)
//     const usage = containerMetricsMap.get(c.name)?.usage
//     containersArray.push({
//       ...c,
//       ...status,
//       usage,
//       clusterId,
//       podId: id,
//     })
//   }
//   return containersArray
// }

// Optimisations for the function above:
const getContainerInfo = (
  clusterId,
  id,
  containers = emptyArr,
  containerStatuses = emptyArr,
  containerMetrics = emptyArr,
) => {
  // Pre-allocate array
  const containersArray = new Array(containers.length)

  // Use Map for O(1) lookups
  const statusMap = new Map(containerStatuses.map((s) => [s.name, s]))
  const metricsMap = new Map(containerMetrics.map((m) => [m.name, m]))

  // Single pass population
  for (let i = 0; i < containers.length; i++) {
    const c = containers[i]
    containersArray[i] = {
      ...c,
      ...statusMap.get(c.name),
      usage: metricsMap.get(c.name)?.usage,
      clusterId,
      podId: id,
    }
  }
  return containersArray
}

const getPodAndContainerStatuses = memoize((containerStats = emptyArr) => {
  // We will loop through all the container statuses, parse them, and at the same time, choose
  // which one will be set as the main pod status. The first container with the highest priority
  // (a.k.a has an error message) will be set as the main pod status
  let podStatus: PodStatus = {
    state: 'Running',
    isError: false,
  }
  // A container can be in one of 3 states: terminated, waiting, or running
  const containerStatuses = containerStats.map((stat) => {
    let containerStatus = null
    if (stat?.state?.terminated) {
      const terminatedStatus = {
        state: 'Terminated',
        reason: stat?.state?.terminated?.reason || 'Terminated',
        // If a message doesn't exist, surface the exit code or signal
        message:
          stat?.state?.terminated?.message ||
          (stat?.state?.terminated?.exitCode &&
            `Exit Code: ${stat?.state?.terminated?.existCode}`) ||
          (stat?.state?.terminated?.signal && `Signal: ${stat?.state?.terminated?.signal}`) ||
          '',
        isError: true,
      }
      if (!podStatus?.isError) {
        podStatus = terminatedStatus
      }
      containerStatus = terminatedStatus
    } else if (stat?.state?.waiting) {
      const waitingStatus = {
        ...stat,
        state: 'Waiting',
        reason: stat?.state?.waiting?.reason || 'Waiting',
        message: stat?.state?.waiting?.message || '',
        isError:
          stat?.state?.waiting?.reason !== 'ContainerCreating' ||
          stat?.state?.waiting?.reason !== 'PodInitializing',
      }
      if (!podStatus?.isError && waitingStatus.isError) {
        podStatus = waitingStatus
      }
      containerStatus = waitingStatus
    } else {
      containerStatus = {
        state: 'Running',
        isError: false,
      }
    }
    return {
      ...stat,
      status: containerStatus,
    }
  })
  return {
    podStatus: podStatus,
    containerStatuses,
  }
})

export const podsSelector: AppSelector<IPodSelector[]> = createSharedSelector(
  getDataSelector<DataKeys.Pods>(
    DataKeys.Pods,
    ['clusterId', 'namespace'],
    ['clusterId', 'namespace'],
  ),
  podMetricsByClusterIdAndNamespaceSelector,
  allClustersSelector,
  qbertEndpointSelector,
  (rawPods, metricsMap, allClusters, qbertEndpoint): IPodSelector[] => {
    // Create cluster lookup map once
    const allClustersMap = new Map(allClusters.map((c) => [c.uuid, c]))

    // Pre-allocate result array
    const result = new Array(rawPods.length)
    let validPodCount = 0
    // Single pass transformation
    for (const pod of rawPods) {
      const { clusterId, id } = pod
      const cluster = allClustersMap.get(pod.clusterId)
      if (!cluster) continue

      const name = pod?.metadata?.name // pathStr('metadata.name', pod),
      const namespace = pod?.metadata?.namespace // pathStr('metadata.namespace', pod),

      const podMetrics = metricsMap?.[clusterId]?.[namespace]?.find(
        (metric) => name && metric?.name === name,
      )
      const k8sDashboardUrl = getK8sDashboardLinkFromVersion(qbertEndpoint, cluster)
      const dashboardUrl = `${k8sDashboardUrl}#/pod/${namespace}/${name}?namespace=${namespace}`
      const logUrls = (pod?.spec?.containers || []).map((container) => {
        const logsEndpoint = pathJoin(
          getScopedClusterProxyEndpoint(qbertEndpoint, cluster),
          '/namespaces/', // qbert v3 link fails authorization so we have to use v1 link for logs
          namespace,
          'pods',
          name,
          'log',
        )
        return {
          containerName: container.name,
          url: `${logsEndpoint}?container=${container.name}`,
        }
      })
      const resourceStats = getPodResourceStats(pod)

      const { podStatus, containerStatuses } = getPodAndContainerStatuses(
        pod?.status?.containerStatuses,
      )
      const containers = getContainerInfo(
        clusterId,
        id,
        pod?.spec?.containers,
        containerStatuses,
        podMetrics?.containers,
      )
      const creationTimestamp = pod?.metadata?.creationTimestamp

      const transformedPod = {
        ...pod,
        dashboardUrl,
        id: pod?.metadata?.uid, // pathStr('metadata.uid', pod),
        name,
        namespace,
        labels: pod?.metadata?.labels, // pathStr('metadata.labels', pod),
        annotations: pod?.metadata?.annotations,
        clusterName: cluster?.name,
        logs: logUrls,
        resourceStats,
        kind: 'Pod',
        podStatus,
        containers,
        metrics: podMetrics,
        qosClass: pod?.status?.qosClass,
        creationTimestamp,
        age: durationBetweenDates({ labels: ['d'] })(creationTimestamp),
      }

      result[validPodCount++] = transformedPod
    }
    // Trim array to actual size
    result.length = validPodCount
    return result
  },
)

export const rawPodsSelector = createSharedSelector(
  getDataSelector<DataKeys.Pods>(
    DataKeys.Pods,
    ['clusterId', 'namespace'],
    ['clusterId', 'namespace'],
  ),
  (pods) => {
    return pods
  },
)

export const makePodsSelector = (
  defaultParams = {
    namespace: null,
    orderBy: 'created_at',
    orderDirection: 'desc',
  } as SortConfig & { namespace?: string },
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(podsSelector, selectParams, (pods, params) => {
    const { namespace, orderBy, orderDirection } = params
    return pipe<IPodSelector[], IPodSelector[], IPodSelector[], IPodSelector[]>(
      filterIf(namespace && namespace !== allKey, propEq('namespace', namespace)),
      createSorter({ orderBy, orderDirection }),
      arrayIfEmpty,
    )(pods)
  })
}

type PodsByClusterIdAndNamespaceSelectorModel = {
  [clusterId: string]: {
    [namespace: string]: IPodSelector[]
  }
}
// comment this out for optimization
// export const podsByClusterIdAndNamespaceSelector = createSharedSelector(
//   podsSelector,
//   (rawPods): PodsByClusterIdAndNamespaceSelectorModel =>
//     rawPods.reduce((accum, pod) => {
//       const { clusterId } = pod
//       const namespace = pod?.metadata?.namespace
//       const existingPods = accum[clusterId]?.[namespace] || []
//       const pods: IPodSelector[] = [...existingPods, pod]
//       return assocPath([clusterId, namespace], pods, accum)
//     }, emptyObj),
// )

export const podsByClusterIdAndNamespaceSelector = createSharedSelector(
  podsSelector,
  (rawPods): PodsByClusterIdAndNamespaceSelectorModel => {
    // Pre-allocate result object
    const result = {}

    // Single pass grouping
    for (const pod of rawPods) {
      const { clusterId } = pod
      const namespace = pod?.metadata?.namespace

      // Initialize nested structure if needed
      if (!result[clusterId]) {
        result[clusterId] = {}
      }
      if (!result[clusterId][namespace]) {
        result[clusterId][namespace] = []
      }

      // Direct push instead of spread
      result[clusterId][namespace].push(pod)
    }

    return result
  },
)
