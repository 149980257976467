import { assocPath } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import getDataSelector from 'core/utils/getDataSelector'
import { emptyObj } from 'utils/fp'
import { createSharedSelector } from 'core/utils/selectorHelpers'

// export const podMetricsByClusterIdAndNamespaceSelector = createSharedSelector(
//   getDataSelector<DataKeys.PodMetrics>(DataKeys.PodMetrics, ['clusterId']),
//   (rawPodMetrics) => {
//     return rawPodMetrics.reduce((accum, metric) => {
//       const { clusterId } = metric
//       const namespace = metric?.metadata?.namespace
//       const existingMetrics = accum[clusterId]?.[namespace] || []
//       const metrics = [...existingMetrics, metric]
//       return assocPath([clusterId, namespace], metrics, accum)
//     }, emptyObj)
//   },
// )

export const podMetricsByClusterIdAndNamespaceSelector = createSharedSelector(
  getDataSelector<DataKeys.PodMetrics>(DataKeys.PodMetrics, ['clusterId']),
  (rawPodMetrics) => {
    return rawPodMetrics.reduce((accum, metric) => {
      const { clusterId, metadata: { namespace } = {} } = metric
      if (!accum[clusterId]) accum[clusterId] = {}
      if (!accum[clusterId][namespace]) accum[clusterId][namespace] = []
      accum[clusterId][namespace].push(metric)
      return accum
    }, {} as Record<string, Record<string, any[]>>)
  },
)
